<template>
  <div v-if="!isLoading" class="board-thumb-wrapper">
    <ul class="board-thumb-list">
      <li class="w-100" v-if="posts.length == 0"><p class="text-center fw-bolder text-muted">등록된 게시글이 없습니다.</p></li>
      <li class="board-thumb-item" v-for="(post, idx) in posts" :key="idx">
        <article v-if="post.stt == '11'" class="board-thumb-card board-scrt-card">
          <div class="thumb-content"><div class="title"><span class="mx-auto">운영자에 의해 삭제된 게시글입니다.</span></div></div>
        </article>
        <article v-else-if="checkScrtMd(boardMst, post, session.lrnerId == post.rgtrId, isOper)" class="board-thumb-card board-scrt-card">
          <div class="thumb-content"><div class="title"><span class="mx-auto">비공개 게시글 입니다.</span></div></div>
        </article>
        <article v-else class="board-thumb-card kb-mouse-cursor" @click="goToView(post.boardSecuKey, boardMst.boardId)">
          <div class="thumb-image overflow-hidden">
            <img :src="getThumbUrl(post.thumbFileUrl)" style="width:100%; height: 100%; outline:3px solid #fff; " alt="">
            <div v-if="post.badges && post.badges.length > 0" class="board-badge-area">
              <template v-for="badge in post.badges" :key="badge.boardBadgeSn">
                <div v-if="badge.stt === '00'" class="badge badge-red"><span class="badge-text">{{badge.postBadgeNm}}</span></div>
              </template>
            </div>
          </div>
          <div class="thumb-content">
            <div class="d-flex justify-content-between align-items-center">
              <div class="category" v-if="headers.includes('category')">{{post.cateNm}}</div>
              <div class="icons">
                <div class="icon" v-if="headers.includes('fileAtch') && post.postFileAtchYn == 'Y'"><i class="icon-file" />{{post.atchCnt}}</div>
                <div class="icon" v-if="headers.includes('inqCnt')"><i class="icon-view" />{{post.inqCnt}}</div>
                <div class="icon" v-if="headers.includes('likeCnt')"><i class="icon-like" />{{post.likeCnt}}</div>
              </div>
            </div>
            <div class="title" :title="post.postTitle">
              <span v-if="headers.includes('seq')">{{ (post.postLvl > 0) ? '[답글] ' : post.postIdx + '. '}} </span>
              <span v-if="headers.includes('title')" class="text-truncate">{{post.postTitle}}</span>
              <i v-if="boardMst.newBadgeYn === 'Y' && post.isNew === 'Y'" class="icon-new ms-1"></i>
            </div>
            <div class="metadata">
              <span class="text" v-if="headers.includes('rgtrId')">{{ (boardMst.userScrtYn == 'Y') ? post.userNickNm : post.deptNm + ' ' + post.lrnerNm }}</span>
              <span class="text" v-if="headers.includes('regDt')">{{ timestampToDateFormat(post.regDt, 'yyyy.MM.dd hh:mm') }}</span>
            </div>
          </div>
        </article>
      </li>
    </ul>
  </div>

  <CommonPaginationFront :paging="paging" :page-func="pagingFunc" v-if="isPaging" />
</template>

<script>
import CommonPaginationFront from "@/components/CommonPaginationFront";
import {boardThumbListSetup} from "@/assets/js/modules/board/board-common";

export default {
  name: 'BoardThumbList',
  components: {CommonPaginationFront},
  props: {
    boardMstMap: Object,
    boardCateList: Array,
    isOper: Boolean,
    postGrpNo: String,
    listTotalCount: Number,
  },
  emits: ['update:listTotalCount'],
  setup: boardThumbListSetup
}
</script>