<template>
  <LoadingDiv v-if="isLoading" />
  <div v-else class="board-list-container">
    <ul class="board-list">
      <li class="board-list-item" v-if="posts.length == 0"><p class="text-center fw-bolder text-muted">등록된 게시글이 없습니다.</p></li>
      <li class="board-list-item" v-for="(item, idx) in posts" :key="idx">
        <article v-if="item.stt == '11'" class="board-row"><p class="text-muted">운영자에 의해 삭제된 게시글입니다.</p></article>
        <article v-else class="board-row kb-mouse-cursor" :class="{'opacity-50': item.isRead === 'Y'}" @click="clickDtl(item)" :style="{'margin-left':`${item.postLvl}rem`}">
          <div class="d-flex align-items-center">
            <span v-if="headers.includes('category') && item.cateNm && item.cateNm !== '-'" class="text text-gold me-1">{{item.cateNm}}</span>
            <template v-for="badge in item.badges" :key="badge.boardBadgeSn">
              <div class="badge badge-red"><span class="badge-text">{{badge.postBadgeNm}}</span></div>
            </template>
            <div class="list-content-title overflow-hidden ms-1">
              <div class="d-inline-flex gap-1 w-100">
                <span v-if="headers.includes('seq') && item.postLvl > 0" class="text text-muted">[답글]</span>
                <template v-else-if="headers.includes('seq')">
                  <span v-if="item.topFixYn == 'Y'" class="text text-gold">필독</span>
                  <span v-else class="text text-muted">{{item.postIdx}}.</span>
                </template>
                <span v-if="headers.includes('title')" class="text text-truncate">{{checkScrtMd(boardMst, item, session.lrnerId == item.rgtrId, isOper) ? '비공개 게시글 입니다.': item.postTitle}}</span>
                <i v-if="boardMst.newBadgeYn === 'Y' && item.isNew === 'Y'" class="icon-new"></i>
              </div>
            </div>
          </div>
          <div class="list-meta-content">
            <div class="d-inline-flex flex-wrap gap-2 w-100">
              <div v-if="headers.includes('rgtrId')"><span class="text">{{(boardMst.userScrtYn == 'Y') ? item.userNickNm : item.lrnerNm + ' | ' + item.deptNm}}</span></div>
              <div v-if="headers.includes('regDt')"><span class="text">{{timestampToDateFormat(item.regDt, 'yyyy.MM.dd hh:mm')}}</span></div>
              <div v-if="headers.includes('fileAtch') && item.postFileAtchYn === 'Y'"><span class="text align-text-top"><i class="icon-print opacity-50" /> {{item.atchCnt}}</span></div>
              <div v-if="headers.includes('inqCnt')"><span class="text align-text-top"><i class="icon-views opacity-50" /> {{item.inqCnt}}</span></div>
              <div v-if="headers.includes('likeCnt')"><span class="text align-text-top"><i class="icon-heart opacity-50" :class="{'is-active': item.isLike == 'Y'}" /> {{item.likeCnt}}</span></div>
            </div>
          </div>
        </article>
      </li>
    </ul>
    <CommonPaginationFront :paging="paging" :page-func="pagingFunc" v-if="isPaging" />
  </div>
</template>

<script>
import CommonPaginationFront from "@/components/CommonPaginationFront";
import LoadingDiv from "@/components/common/LoadingDiv.vue";
import {boardMainListSetup} from "@/assets/js/modules/board/board-common";

export default {
  name: 'BoardMainList',
  props: {
    boardMstMap: Object,
    boardCateList: Array,
    isOper: Boolean,
    postGrpNo: String,
    listTotalCount: Number,
  },
  components: {CommonPaginationFront, LoadingDiv},
  emits: ['update:listTotalCount'],
  setup: boardMainListSetup
}
</script>