<template>
  <div class="board-list-container" v-if="!isLoading">
    <div class="board-timeline-wrapper">
      <ul class="board-timeline-list">
        <li v-if="posts.length == 0"><p class="text-center fw-bolder text-muted">등록된 게시글이 없습니다.</p></li>
        <li class="board-timeline-item" v-for="(post, idx) in posts" :key="idx">
          <article v-if="post.stt == '11'" class="board-timeline"><p class="text-center fw-bolder text-muted">운영자에 의해 삭제된 게시글입니다.</p></article>
          <article v-else-if="checkScrtMd(boardMst, post, session.lrnerId == post.rgtrId, isOper)" class="board-timeline"><p class="text-center fw-bolder text-muted">비공개 게시글 입니다.</p></article>
          <article v-else class="board-timeline" :style="{'margin-left':`${post.postLvl}rem`}">
            <div class="timeline-tag" v-if="headers.includes('category') && post.boardCateSn > 0"><p># {{ post.cateNm }}</p></div>
            <div class="timeline-title">
              <span v-if="headers.includes('seq')" class="pe-1">{{post.postLvl > 0 ? '[답글]' : post.postIdx+'.'}}</span>
              <span v-if="headers.includes('title')">{{post.postTitle}}</span>
              <template v-for="badge in post.badges" :key="badge.boardBadgeSn">
                <div v-if="badge.stt === '00'" class="badge badge-red ms-1"><span class="badge-text fs-9">{{badge.postBadgeNm}}</span></div>
              </template>
              <i v-if="boardMst.newBadgeYn === 'Y' && post.isNew === 'Y'" class="icon-new ms-1"></i>
            </div>
            <div class="d-flex justify-content-between w-100">
              <div class="post-info-area">
                <div class="d-flex gap-2">
                  <div class="timeline-avatar" v-if="headers.includes('rgtrId')">
                    <div class="avatar">
                      <div v-if="boardMst.userScrtYn == 'Y' && session.lrnerId != post.rgtrId" class="default-profile" />
                      <ProfileImg v-else :target="post" />
                    </div>
                  </div>
                  <div class="timeline-metadata">
                    <div class="metadata" v-if="headers.includes('regDt')">{{ timestampToDateFormat(post.regDt, 'yyyy.MM.dd hh:mm') }}</div>
                    <div v-if="headers.includes('rgtrId')">
                      <div class="metadata" v-if="boardMst.userScrtYn == 'Y' && session.lrnerId != post.rgtrId">{{ post.userNickNm }}</div>
                      <div class="metadata" v-else>{{ post.deptNm }} {{ post.lrnerNm }}</div>
                    </div>
                  </div>
                </div>
                <div class="d-flex align-items-center gap-1">
                  <!-- 첨부파일 -->
                  <div v-if="post.postFileAtchYn == 'Y'">
                    <div class="kb-form-dropdown download-dropdown" :class="{'is-active': post.toggleAtchFile}" data-offset="bottom-left" @click="getAtchFiles(idx)">
                      <button class="kb-form-dropdown-btn kb-btn kb-btn-secondary kb-btn-sm rounded-lg"><span class="text">첨부파일 {{post.atchCnt > 0 ? post.atchCnt : 0}}</span></button>
                      <div class="kb-form-dropdown-options" v-if="post.toggleAtchFile">
                        <div class="dropdown-option">
                          <ul class="dropdown-option-list" v-for="(file,fileIdx) in files" :key="fileIdx">
                            <li class="dropdown-option-item">
                              <a class="dropdown-option-link kb-mouse-cursor" :title="file.fileNm" @click.stop="downloadAtch(file, idx)">
                                <span class="dropdown-option-text text">{{ (file.fileNm != null ? file.fileNm : '-') }}</span>
                                <span class="download-mb text">{{ (file.fileSz > 0 ? convertToStorageBytes(file.fileSz) : 0) }}</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- 댓글창 -->
                  <div v-if="boardMst.cmntWrtYn == 'Y'">
                    <button class="kb-btn kb-btn-sm kb-btn-secondary" @click="showCmnt(idx)">
                      <span class="text">댓글</span>
                      <span v-if="boardMst.cmntPrntYn == 'Y'" class="text" style="padding-left:var(--kb-spacer-1);">{{post.cmntCnt > 0 ? post.cmntCnt : 0}}</span>
                    </button>
                  </div>
                  <!-- 좋아요 / 담기 -->
                  <div class="timeline-icons">
                    <div class="timeline-icon" v-if="boardMst.postLikeYn == 'Y'" @click="actLike(post)"><p :class="{'is-active': post.isLike == 'Y'}" >좋아요 {{ headers.includes('likeCnt') ? post.likeCnt : ''}}</p></div>
                    <div class="timeline-icon" v-if="boardMst.postPutYn == 'Y'" @click="actPut(post)"><p class="is-active" v-if="post.isPut == 'Y'">담기취소</p><p v-else>담기</p></div>
                  </div>
                </div>
              </div>
              <div class="post-func-area">
                <div v-if="(boardMst.postRewrtYn == 'Y' && post.postLvl == 0) || (boardMst.ansRewrtYn == 'Y' && post.postLvl > 0)">
                  <div class="kb-form-dropdown download-dropdown" :class="{'is-active': post.toggleMore}" data-offset="bottom-right" @click="post.toggleMore = !post.toggleMore">
                    <button class="kb-form-dropdown-btn kb-btn kb-btn-sm"><i class="icon-more"></i></button>
                    <div class="kb-form-dropdown-options" v-if="post.toggleMore">
                      <div class="dropdown-option">
                        <ul class="dropdown-option-list">
                          <li class="dropdown-option-item" v-if="((boardMst.postRewrtYn == 'Y' && post.postLvl == 0) || (boardMst.ansRewrtYn == 'Y' && post.postLvl > 0)) && session.lrnerId == post.rgtrId">
                            <a class="dropdown-option-link kb-mouse-cursor" @click="goToEdit(post.boardSecuKey, boardMst.boardId)"><span class="dropdown-option-text text">수정하기</span></a>
                          </li>
                          <li class="dropdown-option-item" v-if="((boardMst.postRewrtYn == 'Y' && post.postLvl == 0) || (boardMst.ansRewrtYn == 'Y' && post.postLvl > 0)) && session.lrnerId == post.rgtrId">
                            <a class="dropdown-option-link kb-mouse-cursor" @click="deletePost(post.comPostSn, getBoardList)"><span class="dropdown-option-text text">삭제하기</span></a>
                          </li>
                          <li class="dropdown-option-item" v-if="boardMst.ansWrtYn == 'Y'">
                            <a class="dropdown-option-link kb-mouse-cursor" @click="addAnswer(idx)"><span class="dropdown-option-text text">답글쓰기</span></a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="customInfos.length > 0" class="mt-3">
              <ul v-for="customInfo in customInfos" :key="customInfo.boardCstmDataSn">
                <li v-if="customInfo.comPostSn == post.comPostSn" class="mb-2"><span class="text-muted me-2">{{customInfo.itemNm}}</span><span>{{customInfo.value ? customInfo.value : '-'}}</span></li>
              </ul>
            </div>
            <div class="board-content ck-content" v-html="post.postCn"></div>
            <div v-if="boardMst.postEvalYn == 'Y'">
              <board-evaluation :com-post-sn="post.comPostSn" :post-star-score="post.starScore" />
            </div>
            <div class="board-timeline-comment" v-if="boardMst.cmntWrtYn == 'Y' && post.toggleCmnt">
              <common-board-comment :board-mst-map="boardMst" :board-secu-key="post.boardSecuKey" :com-post-sn="post.comPostSn" :is-oper="isOper" :show-empty="true" />
            </div>
          </article>
        </li>
      </ul>
      <CommonPaginationFront :paging="paging" :page-func="pagingFunc" v-if="isPaging" />
    </div>
  </div>
</template>

<script>
import ProfileImg from '@/components/common/ProfileImg';
import CommonPaginationFront from "@/components/CommonPaginationFront";
import CommonBoardComment from "@/components/board/CommonBoardComment";
import BoardEvaluation from "@/components/board/BoardEvaluation";
import {boardTimelineSetup} from "@/assets/js/modules/board/board-common";

export default {
  name: "BoardTimeline",
  props: {
    boardMstMap: Object,
    boardCateList: Array,
    isOper: Boolean,
    postGrpNo: String,
    listTotalCount: Number,
  },
  components: {ProfileImg, CommonPaginationFront, CommonBoardComment, BoardEvaluation},
  emits: ['update:listTotalCount'],
  setup: boardTimelineSetup
}
</script>